import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Orders from '../../components/pages/Orders'
import { setTableFilter, setTableSorting, setUiValue } from '../../store/actions'

const mapStateToProps = state => {

    return {

        date:          state.ui.date,
        isLoading:     state.app.isLoading,
        name:          state.ui.name,
        passNo:        state.ui.passNo,
        tableFilter:   ( 'orders' in state.ui.tableFilters  ) ? state.ui.tableFilters.orders  : '',
        tableSorting:  ( 'orders' in state.ui.tableSortings ) ? state.ui.tableSortings.orders : null,
        token:         state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onTableFilterChange:  filter => dispatch( setTableFilter( filter, 'orders' ) ),
        onTableSortingChange: sorting => dispatch( setTableSorting( sorting, 'orders' ) ),
        onToggleIsLoading:    isLoading => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),
        onUiValueChange:      ( key, value ) => dispatch( setUiValue( key, value ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Orders )