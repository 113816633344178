import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Licenses from '../../components/pages/Licenses'
import { setTableFilter, setTableSorting, setUiValue } from '../../store/actions'

const mapStateToProps = state => {

    return {

        date:          state.ui.date,
        isLoading:     state.app.isLoading,
        name:          state.ui.name,
        passNo:        state.ui.passNo,
        tableFilter:   ( 'licenses' in state.ui.tableFilters  ) ? state.ui.tableFilters.licenses  : '',
        tableSorting:  ( 'licenses' in state.ui.tableSortings ) ? state.ui.tableSortings.licenses : null,
        token:         state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onTableFilterChange:  filter => dispatch( setTableFilter( filter, 'licenses' ) ),
        onTableSortingChange: sorting => dispatch( setTableSorting( sorting, 'licenses' ) ),
        onToggleIsLoading:    isLoading => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),
        onUiValueChange:      ( key, value ) => dispatch( setUiValue( key, value ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Licenses )