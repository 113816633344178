import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Badge, Button, Card, Col, DropdownButton, FormCheck, Modal, Row } from 'react-bootstrap'
import { convertUrlParams, deepCopy, getLicenseTypeLabel, licenseTypes, orderStatusLabels } from '../../util'
import { GENDER_FEMALE, ORDER_STATUS_CANCELED } from '../../util/constants'
import { formatDate, formatTime } from '../../util/date'
import Page from '../templates/Page'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import ManualPayment from '../elements/ManualPayment'

class Orders extends Page {

    constructor( props ) {

        super( props )

        this.state = {

            cancelingOrdersSuccessful: false,
            hasCancelingOrdersError:   false,
            isCancelingOrders:         false,
            licenses:                  null,
            manualPaymentModalOpen:    false,
            ordersToCancel:            null,
            selectedOrders:            [],

        }

        this.pageIcon        = 'list'
        this.pageTitle       = _( 'Bestellungen' )
        this.tableColumns    = this.getTableColumns()
        this.tableDataUrl    = 'api/Admin/GetOrders'
        this.tableFilterKeys = [ 'Status', 'OrderNr', 'LicenseHolder', 'PassNr', 'Gateway', 'PaymentNr', 'Currency', 'TotalAmount' ]
        this.tableSortKeys   = [ '', '', 'Status', 'OrderNr', 'LicenseHolder', 'Gender', 'PassNr', 'Type', 'CreateDate', 'Gateway', 'PaymentNr', 'Currency', 'TotalAmount' ]

    }

    beforeLoadingTableData() {

        this.setState( { selectedOrders: [] } )

    }

    cancelOrders( ordersToCancel ) {

        this.setState( { ordersToCancel } )

    }

    closeCancelOrdersModal() {

        this.setState( { cancelingOrdersSuccessful: false, hasCancelingOrdersError: false, isCancelingOrders: false, ordersToCancel: null } )

    }

    closeLicensesModal() {

        this.setState( { licenses: null } )

    }

    closeManualPaymentModal( order ) {

        this.setState( { manualPaymentModalOpen: false } )

        if ( order ) {

            const tableData = deepCopy( this.state.tableData )

            tableData.push( order )

            this.setState( { tableData }, () => this.filterAndSortTableData() )

        }

    }

    confirmCancelingOrders() {

        this.setState( { hasCancelingOrdersError: false, isCancelingOrders: true }, () => {

            const success = () => {

                const tableData = deepCopy( this.state.tableData )

                tableData.forEach( e => {

                    if ( this.state.ordersToCancel.indexOf( e.Id ) !== -1 ) {

                        e.Status = ORDER_STATUS_CANCELED

                    }

                } )

                this.setState( { cancelingOrdersSuccessful: true, tableData }, () => this.filterAndSortTableData() )

            }

            ajax( getDataUrl( 'api/Admin/CancelPayment' ) + '?' + convertUrlParams( { token: this.props.token } ), { paymIds: { __arr: true, values: this.state.ordersToCancel } }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success() : this.errorCancelingOrders() )
                .catch( e => this.errorCancelingOrders() )
                .finally( () => this.setState( { isCancelingOrders: false } ) )

        } )

    }

    downloadPaymentPdf( paymentId ) {

        window.location.href = getDataUrl( 'api/SingleLicense/GetPaymentPdf' ) + '?' + convertUrlParams( { paymentId } )

    }

    errorCancelingOrders() {

        this.setState( { hasCancelingOrdersError: true } )

    }

    getCheckbox( orderId ) {

        return <FormCheck inline onChange={ e => this.handleOrderSelect( e, orderId ) } checked={ this.state.selectedOrders.indexOf( orderId ) !== -1 } />

    }

    getOptionsButton( orderId ) {

        return (

            <DropdownButton title={ <Icon icon='bars' /> } variant='link'>

                <DropdownItem onClick={ () => this.cancelOrders( [ orderId ] ) }>{ _( 'Stornieren' ) }</DropdownItem>
                <DropdownItem onClick={ () => this.downloadPaymentPdf( orderId ) }>{ _( 'Rechnung herunterladen' ) }</DropdownItem>

            </DropdownButton>

        )

    }

    getStatusLabel( status ) {

        return <Badge bg={ ( status in orderStatusLabels ) ? orderStatusLabels[ status ] : 'secondary' }>{ status }</Badge>

    }

    getTableColumns() {

        return [

            { fn: e => this.getOptionsButton( e.Id ) },
            { fn: e => this.getCheckbox( e.Id ) },
            { fn: e => this.getStatusLabel( e.Status ) },
            { key: 'OrderNr' },
            { key: 'LicenseHolder' },
            { fn: e => <Icon icon={ e.Gender === GENDER_FEMALE ? 'venus' : 'mars' } /> },
            { key: 'PassNr' },
            { fn: e => this.renderLicenseColumn( e ) },
            { fn: e => formatDate( e.CreateDate ) + ' ' + formatTime( e.CreateDate ) },
            { key: 'Gateway' },
            { key: 'PaymentNr' },
            { key: 'Currency' },
            { key: 'TotalAmount' },

        ]

    }

    getTableHeaders() {

        const checkbox = <FormCheck
                            checked={ this.state.tableRows && this.state.tableRows.length ? this.state.selectedOrders.length === this.state.tableRows.length : false }
                            disabled={ this.props.isLoading || ! this.state.tableRows || ! this.state.tableRows.length }
                            inline
                            onChange={ e => this.handleAllOrdersSelect( e ) }
                        />

        return [

            { html: '',       sortable: false },
            { html: checkbox, sortable: false },
            _( 'Status'            ),
            _( 'Bestellnr.'        ),
            _( 'Name'              ),
            _( 'Geschlecht'        ),
            _( 'Passnr.'           ),
            _( 'Lizenz'            ),
            _( 'Datum / Uhrzeit'   ),
            _( 'Bezahlmethode'     ),
            _( 'Bezahlvorgangsnr.' ),
            _( 'Währung'           ),
            _( 'Betrag bezahlt'    ),

        ]

    }

    handleAllOrdersSelect( e ) {

        const selectedOrders = []

        e.target.checked && this.state.tableRows && this.state.tableRows.forEach( e => selectedOrders.push( e.Id ) )

        this.setState( { selectedOrders } )

    }

    handleOrderSelect( e, orderId ) {

        let selectedOrders = deepCopy( this.state.selectedOrders )
      const index          = selectedOrders.indexOf( orderId )

      e.target.checked ? index === -1 && selectedOrders.push( orderId ) : index !== -1 && selectedOrders.splice( index, 1 )

      this.setState( { selectedOrders } )

    }

    showManualPaymentModal() {

        this.setState( { manualPaymentModalOpen: true } )

    }

    renderFooter() {

        return (

            <>

                <Modal show={ this.state.ordersToCancel !== null } onHide={ () => this.closeCancelOrdersModal() } centered>

                    <Modal.Header closeButton>

                        <Modal.Title>{ _( 'Bestellungen stornieren' ) }</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {

                            this.state.cancelingOrdersSuccessful ?

                                <Alert variant='success' className='my-5 text-center fs-3'><Icon icon='check' /> { _( 'Bestellungen wurden storniert.' ) }</Alert>

                            :

                                <>

                                    <p className='lead'>{ _( 'Sollen folgende Bestellungen wirklich storniert werden?' ) }</p>

                                    { this.state.ordersToCancel && this.state.ordersToCancel.map( ( orderId, i ) => {

                                        const order = this.state.tableData.find( e => e.Id === orderId )

                                        return (

                                            <Row key={ i }>

                                                <Col>{ order.OrderNr || ( '[' + _( 'Keine Bestellnr.' ) + ']' ) }</Col>
                                                <Col>{ order.LicenseHolder }</Col>

                                            </Row>

                                        )

                                    } ) }

                                    { this.state.hasCancelingOrdersError && <Alert variant='warning' className='mt-5 mb-0'><Icon icon='exclamation-triangle' /> { _( 'Bestellungen konnten nicht storniert werden. Ein unbekannter Fehler ist aufgetreten.' ) }</Alert> }

                                </>

                        }

                    </Modal.Body>

                    <Modal.Footer>

                        {

                            this.state.cancelingOrdersSuccessful ?

                                <Button onClick={ () => this.closeCancelOrdersModal() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                            :

                                <>

                                    <Button onClick={ () => this.closeCancelOrdersModal() } disabled={ this.state.isCancelingOrders } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                                    <Button onClick={ () => this.confirmCancelingOrders() } disabled={ this.state.isCancelingOrders } variant='danger'>{ _( 'Stornieren' ) }</Button>

                                </>

                        }

                    </Modal.Footer>

                </Modal>

                <Modal show={ this.state.licenses !== null } onHide={ () => this.closeLicensesModal() } centered>

                    <Modal.Header closeButton>

                        <Modal.Title>{ _( 'Lizenzen' ) }</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        { this.state.licenses && this.state.licenses.map( ( e, i ) => <Card key={ i } className='mb-2'>

                            <Card.Body>

                                <Row>

                                    <Col xs='4'>{ _( 'Name' ) }</Col>
                                    <Col>{ e.Name }</Col>

                                </Row>

                                <Row>

                                    <Col xs='4'>{ _( 'Passnr.' ) }</Col>
                                    <Col>{ e.PassNr }</Col>

                                </Row>

                                <Row>

                                    <Col xs='4'>{ _( 'Lizenznr.' ) }</Col>
                                    <Col>{ e.LicenseNr }</Col>

                                </Row>

                                <Row>

                                    <Col xs='4'>{ _( 'Lizenz' ) }</Col>
                                    <Col>{ getLicenseTypeLabel( e.Type ) }</Col>

                                </Row>

                                <Row>

                                    <Col xs='4'>{ _( 'Zeitraum' ) }</Col>
                                    <Col>{ formatDate( e.From ) } - { formatDate( e.To ) }</Col>

                                </Row>

                            </Card.Body>

                        </Card> ) }

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.closeLicensesModal() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <ManualPayment
                    onClose={ e => this.closeManualPaymentModal( e ) }
                    show={ this.state.manualPaymentModalOpen }
                    token={ this.props.token }
                />

            </>

        )

    }

    renderLicenseColumn( e ) {

        const badge = getLicenseTypeLabel( licenseTypes.indexOf( e.Type ) )

        return (

            e.Licenses.length ?

                <div role='button' onClick={ () => this.setState( { licenses: e.Licenses } ) }>

                    { badge }

                    <Badge pill bg='info' className='ms-1'>{ e.Licenses.length }</Badge>

                </div>

            :

                badge

        )

    }

    renderSubheaderBlock() {

        return (

            <div className='subheader-block'>

                <Button onClick={ () => this.showManualPaymentModal() }><Icon icon='plus' /> { _( 'Manuelle Zahlung' ) }</Button>

            </div>

        )

    }

    renderTableOptions() {

        return (

            <Row className='align-items-end mt-3'>

                <Col xs='12' md='2'>

                    <div className='d-grid'>

                        <Button variant='secondary' className='mb-3' disabled={ this.props.isLoading || ! this.state.tableRows || ! this.state.tableRows.length || ! this.state.selectedOrders.length } onClick={ () => this.cancelOrders( this.state.selectedOrders ) }>

                            <div className='d-md-none'>{ _( 'Auswahl stornieren' ) }</div>

                            <div className='d-none d-md-block'>{ _( 'Auswahl' ) }<br />{ _( 'stornieren' ) }</div>

                        </Button>

                    </div>

                </Col>

                <Col>

                    { this.renderTableFilter() }

                </Col>

            </Row>

        )

    }

}

export default Orders