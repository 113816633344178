import { actionTypes } from '@hockeydata/skynet'
import { SET_TABLE_FILTER, SET_TABLE_SORTING, SET_UI_VALUE } from './constants'

const initialStateUi = {

    date:          '',
    name:          '',
    passNo:        '',
    tableFilters:  {},
    tableSortings: {},

}

export const ui = ( state = initialStateUi, action ) => {

    switch ( action.type ) {

        case actionTypes.LOGOUT: {

            return initialStateUi

        }

        case SET_TABLE_FILTER: {

            return { ...state, tableFilters: { ...state.tableFilters, [ action.payload.tableName ]: action.payload.filter } }

        }

        case SET_TABLE_SORTING: {

            return { ...state, tableSortings: { ...state.tableSortings, [ action.payload.tableName ]: action.payload.sorting } }

        }

        case SET_UI_VALUE: {

            return { ...state, [ action.payload.key ]: action.payload.value }

        }

        default:

            return state

    }

}