import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { convertUrlParams, deepCopy, genders, licenseTypes } from '../../util'
import { licenseTypes as licenseTypesEnum, genders as gendersEnum } from '../../util/enum'
import { GENDER_FEMALE, LICENSE_TYPE_PLAYER } from '../../util/constants'

class ManualPayment extends React.Component {

    #genders
    #licenseTypes

    constructor( props ) {

        super( props );

        this.state         = this.getDefaultState()
        this.#genders      = gendersEnum.map( ( e, i ) => { return { value: genders[ i ], label: e } } )
        this.#licenseTypes = licenseTypesEnum.map( ( e, i ) => { return { value: licenseTypes[ i ], label: e } } )

    }

    componentDidMount() {

        this.load()

    }

    changeFee( e, feeId ) {

        let value = e.target.value

        if ( value !== '' ) {

            value = Number( value )

        }

        if ( isNaN( value ) ) {

            value = ''

        }

        this.setState( { manualPayment: { ...this.state.manualPayment, fees: { ...this.state.manualPayment.fees, [ feeId ]: value } } } )

    }

    changeManualPayment( e ) {

        let value = e.target.value

        if ( e.target.type === 'checkbox' ) {

            value = e.target.checked

        }

        this.setState( { manualPayment: { ...this.state.manualPayment, [ e.target.name ]: value } } )


    }

    close() {

        const order = this.state.order

        this.setState( this.getDefaultState(), () => this.props.onClose( order ) )

    }

    error() {

        this.setState( { hasError: true } )

    }

    getDefaultState() {

        return {

            hasError:         false,
            isSaving:         false,
            order:            null,
            savingSuccessful: false,

            manualPayment: {

                city:          '',
                country:       '',
                email:         '',
                fees:          {},
                firstname:     '',
                forFree:       false,
                gender:        GENDER_FEMALE,
                houseNo:       '',
                lastname:      '',
                legalGuardian: '',
                passNr:        '',
                postalcode:    '',
                street:        '',
                typeStr:       LICENSE_TYPE_PLAYER,
                withSendMail:  false,

            },

        }

    }

    load() {

        ajax( getDataUrl( 'api/Country/Get'          ),                                                         null, { method: 'POST' } ).then( e => e.StatusId > 0 && this.setState( { countries: e.Data } ) )
        ajax( getDataUrl( 'api/SingleLicenseFee/Get' ) + '?' + convertUrlParams( { token: this.props.token } ), null, { method: 'POST' } ).then( e => e.StatusId > 0 && this.setState( { fees:      e.Data } ) )

    }

    save( e ) {

        e.preventDefault()

        this.setState( { hasError: false, isSaving: true }, () => {

            const data = deepCopy( this.state.manualPayment )
            const fees = []

            Object.keys( data.fees ).forEach( feeId => {

                data.fees[ feeId ] > 0 && Array.from( { length: data.fees[ feeId ] } ).forEach( () => fees.push( Number( feeId ) ) )

            } )

            data.fees = { __arr: true, values: fees }

            ajax( getDataUrl( 'api/Admin/CreateManualPayment' ) + '?' + convertUrlParams( { token: this.props.token } ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { savingSuccessful: true, order: e.Data[ 0 ] } ) : this.error() )
                .catch( e => this.error( e ) )
                .finally( () => this.setState( { isSaving: false } ) )

        } )

    }

    renderFee( fee, i ) {

        return (

            <Row key={ i } className='mb-1 align-items-center'>

                <Col xs='3' md='2'><Form.Control type='number' min='0' size='sm' value={ ( fee.Id in this.state.manualPayment.fees ) ? this.state.manualPayment.fees[ fee.Id ] : '' } onChange={ e => this.changeFee( e, fee.Id ) } /></Col>
                <Col xs='9' className='d-md-none'>{ fee.Name } (€{ fee.Amount.toFixed( 2 ) }, { fee.TaxRate }%)</Col>
                <Col md='5' className='d-none d-md-block'>{ fee.Name }</Col>
                <Col md='2' className='d-none d-md-block'>€ { fee.Amount.toFixed( 2 ) }</Col>
                <Col md='2' className='d-none d-md-block'>{ fee.TaxRate } %</Col>

            </Row>

        )

    }

    renderInput( options ) {

        let value = this.state.manualPayment[ options.name ]

        return (

            <Form.Group as={ Row } className='mb-3' controlId={ options.name }>

                <Form.Label column sm={ 3 }>{ options.type !== 'switch' && options.label }</Form.Label>

                <Col sm={ 9 }>

                    {

                        options.type === 'switch' ?

                            <div>

                                <Form.Check
                                    checked={ value }
                                    label={ options.label }
                                    name={ options.name }
                                    onChange={ e => this.changeManualPayment( e ) }
                                    type='switch'
                                />

                            </div>

                        : options.type === 'select' ?

                            <Form.Select value={ value } name={ options.name } onChange={ e => this.changeManualPayment( e ) }>

                                { options.emptyOption && <option value=''></option> }

                                { options.options && options.options.map( ( option, i ) => <option value={ option.value } key={ i }>{ option.label }</option> ) }

                            </Form.Select>

                        :

                            <Form.Control
                                name={ options.name }
                                onChange={ e => this.changeManualPayment( e ) }
                                type={ options.inputType || 'text' }
                                value={ value }
                            />

                    }

                    { options.infoText }

                </Col>

            </Form.Group>

        )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ () => this.close() } centered size='lg' backdrop='static' keyboard={ false }>

                <Form onSubmit={ e => this.save( e ) }>

                    <Modal.Header closeButton>

                        <Modal.Title>{ _( 'Manuelle Zahlung' ) }</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {

                            this.state.savingSuccessful ?

                                <Alert variant='success' className='my-5 text-center fs-3'><Icon icon='check' /> { _( 'Manuelle Zahlung wurde gespeichert.' ) }</Alert>

                            :

                                <>

                                    <fieldset disabled={ this.state.isSaving }>

                                        { this.renderInput( { name: 'firstname',     label: _( 'Vorname'                  )                                              } ) }
                                        { this.renderInput( { name: 'lastname',      label: _( 'Nachname'                 )                                              } ) }
                                        { this.renderInput( { name: 'gender',        label: _( 'Geschlecht'               ), type: 'select', options: this.#genders      } ) }
                                        { this.renderInput( { name: 'passNr',        label: _( 'Passnummer'               )                                              } ) }
                                        { this.renderInput( { name: 'typeStr',       label: _( 'Lizenztyp'                ), type: 'select', options: this.#licenseTypes } ) }
                                        { this.renderInput( { name: 'forFree',       label: _( 'Kostenlos'                ), type: 'switch'                              } ) }
                                        { this.renderInput( { name: 'legalGuardian', label: _( 'Erziehungsberechtigte(r)' )                                              } ) }

                                        <Form.Group as={ Row } className='mb-3'>

                                            <Form.Label column sm={ 3 }>{ _( 'Straße / Hausnummer' ) }</Form.Label>

                                            <Col sm={ 9 }>

                                                <InputGroup>

                                                    <Form.Control value={ this.state.manualPayment.street } name='street' onChange={ e => this.changeManualPayment( e ) } />

                                                    <Form.Control value={ this.state.manualPayment.houseNo } name='houseNo' onChange={ e => this.changeManualPayment( e ) } className='flex-grow-0' style={ { width: '8rem' } } />

                                                </InputGroup>

                                            </Col>

                                        </Form.Group>

                                        { this.renderInput( { name: 'postalcode',   label: _( 'PLZ'            ) } ) }
                                        { this.renderInput( { name: 'city',         label: _( 'Ort'            ) } ) }
                                        { this.renderInput( { name: 'country',      label: _( 'Land'           ), type: 'select', emptyOption: true, options: this.state.countries && this.state.countries.map( e => { return { value: e.Identifier, label: e.Name } } ) } ) }
                                        { this.renderInput( { name: 'email',        label: _( 'E-Mail-Adresse' ) } ) }
                                        { this.renderInput( { name: 'withSendMail', label: _( 'Rechnnung und bestellte Einzellizenzen an angegebene E-Mail-Adresse senden' ), type: 'switch' } ) }

                                        <h3 className=''>{ _( 'Lizenzen' ) }</h3>

                                        <Row className='border-bottom mb-1'>

                                            <Col xs='3' md='2' className='text-center'>{ _( 'Anzahl' ) }</Col>
                                            <Col xs='9' md='5'></Col>
                                            <Col md='2' className='d-none d-md-block'>{ _( 'Preis' ) }</Col>
                                            <Col md='2' className='d-none d-md-block'>{ _( 'Steuer' ) }</Col>

                                        </Row>

                                        { this.state.fees && this.state.fees.map( ( e, i ) => this.renderFee( e, i ) ) }

                                    </fieldset>

    {/*

    fees                table           SingleLicenseFee/Get -> Id; Anzeigen: Minitabelle (Name, Amount, TaxRate) mit Textfelder für Anzahl
    */}

                                    { this.state.hasError && <Alert variant='warning' className='mt-5 mb-0'><Icon icon='exclamation-triangle' /> { _( 'Manuelle Zahlung konnte nicht gespeichert werden. Ein unbekannter Fehler ist aufgetreten.' ) }</Alert> }

                                </>

                        }

                    </Modal.Body>

                    <Modal.Footer>

                        {

                            this.state.savingSuccessful ?

                                <Button onClick={ () => this.close() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                            :

                                <>

                                    <Button onClick={ () => this.close() } disabled={ this.state.isSaving } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                                    <Button type='submit' disabled={ this.state.isSaving }>{ _( 'Speichern' ) }</Button>

                                </>

                        }

                    </Modal.Footer>

                </Form>

            </Modal>

        )

    }

}

export default ManualPayment