import { SET_TABLE_FILTER, SET_TABLE_SORTING, SET_UI_VALUE } from './constants'

export const setTableFilter = ( filter, tableName ) => {

    return {

        type:    SET_TABLE_FILTER,
        payload: { filter, tableName },

    }

}

export const setTableSorting = ( sorting, tableName ) => {

    return {

        type:    SET_TABLE_SORTING,
        payload: { sorting, tableName },

    }

}

export const setUiValue = ( key, value ) => {

    return {

        type:    SET_UI_VALUE,
        payload: { key, value },

    }

}