import React from 'react'
import { Icon, translate as _ } from '@hockeydata/skynet'
import { Button, Placeholder, Table } from 'react-bootstrap'
import { SORT_ASC, SORT_DESC } from '../../util/constants'

class DataTable extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            order:   props.order || 'asc',
            orderBy: props.orderBy || 0,

        }

    }

    handleSortingChange( column ) {

        let direction = SORT_ASC

        if ( this.props.sorting && this.props.sorting.column === column && this.props.sorting.direction === SORT_ASC ) {

            direction = SORT_DESC

        }

        this.props.onSortingChange( { column, direction } )

    }

    renderColumn( columnDefinition, row, i ) {

        let html      = ''
        let className = columnDefinition.className || ''

        if ( 'fn' in columnDefinition ) {

            html = columnDefinition.fn( row )

        } else if ( 'key' in columnDefinition ) {

            html = ( columnDefinition.key in row ) ? row[ columnDefinition.key ] : ''

        }

        if ( columnDefinition.sticky ) {

            className += ' sticky'

        }

        return <td key={ i } className={ className } { ...columnDefinition.attr }>{ html }</td>

    }

    renderHeader( nodeName ) {

        if ( this.props.headers ) {

            return React.createElement( nodeName, null, <tr>{ this.props.headers.map( ( e, i ) => this.renderHeaderColumn( e, i ) ) }</tr> )

        }

    }

    renderHeaderColumn( e, i ) {

        let className = ''
        let html      = ''
        let sortable  = true

        if ( typeof e === 'string' || React.isValidElement( e ) ) {

            html = e

        } else {

            if ( e.html ) {

                html = e.html

            }

            if ( e.className ) {

                className += e.className

            }

            if ( ( 'sortable' in e ) && ! e.sortable ) {

                sortable = false

            }

        }

        return (

            <th key={ i } className={ className }>

                { this.props.onSortingChange && sortable ? <Button variant='link' onClick={ () => this.handleSortingChange( i ) }>{ html }</Button> : html }

                { sortable && <Icon icon={ this.props.sorting && this.props.sorting.column === i ? ( this.props.sorting.direction === SORT_ASC ? 'caret-up' : 'caret-down' ) : '' } fw className='ms-1' /> }

            </th>

        )

    }

    render() {

        return (

            <Table responsive striped className='data-table' size={ this.props.size } variant={ this.props.variant }>

                { this.renderHeader( 'thead' ) }

                <tbody>

                    {

                        this.props.rows ?

                            ( this.props.rows.length ?

                                this.props.rows.map( ( row, i ) =>

                                    <tr key={ i }>

                                        { this.props.cols.map( ( e, i ) => this.renderColumn( e, row, i ) ) }

                                    </tr>

                                )

                            :

                                <tr><td colSpan='100' className='text-center'>{ _( 'Keine Einträge gefunden.' ) }</td></tr>

                            )

                        :

                            Array.from( { length: Number( this.props.placeHolderRows ) || 3 } ).map( ( _, i ) => <tr key={ i }>{ Array.from( { length: this.props.cols ? this.props.cols.length : Number( this.props.placeHolderCols ) || 3 } ).map( ( _, i ) => <td key={ i }><Placeholder animation='wave'><Placeholder xs={12} /></Placeholder></td> ) }</tr> )

                    }

                </tbody>

                { this.renderHeader( 'tfoot' ) }

            </Table>

        )

    }

}

export default DataTable