import { Badge } from 'react-bootstrap'
import { GENDER_FEMALE, GENDER_MALE, LICENSE_TYPE_COACH, LICENSE_TYPE_PLAYER, LICENSE_TYPE_REFEREE, ORDER_STATUS_CANCELED, ORDER_STATUS_COMPLETED, ORDER_STATUS_FORWARDED } from './constants'
import { licenseTypes as licenseTypesEnum } from './enum'

const styles = [ 'primary', 'secondary', 'success', 'danger', 'warning', 'info' ]

export const genders = [ GENDER_FEMALE, GENDER_MALE ]

export const licenseTypes = [ LICENSE_TYPE_PLAYER, LICENSE_TYPE_COACH, LICENSE_TYPE_REFEREE ]

export const orderStatusLabels = {

    [ ORDER_STATUS_CANCELED  ]: 'warning',
    [ ORDER_STATUS_COMPLETED ]: 'success',
    [ ORDER_STATUS_FORWARDED ]: 'primary',

}

export const convertUrlParams = e => {

    const params = []

    e && Object.keys( e ).forEach( key => { params.push( key + '=' + e[ key ] ) } )

    return params.length ? params.join( '&' ) : ''

}

export const deepCopy = e => window.structuredClone ? window.structuredClone( e ) : parseJSON( JSON.stringify( e ) )

export const filterData = ( arr, keys, filter ) => {

    const regex = new RegExp( filter, 'gi' )

    return arr.filter( e => {

        let found = false

        keys.forEach( key => {

            if ( ( key in e ) && regex.test( e[ key ] ) ) {

                found = true

            }

        } )

        return found

    } )

}

export const getLicenseTypeLabel = e => <Badge bg={ getStyle( e ) }>{ licenseTypesEnum[ e ] }</Badge>

export const getStyle = e => {

    return styles[ e < 0 || e > styles.length - 1 ? 0 : e ]

}

export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const lz = ( e, length = 2 ) => {

    e = e + ''

    while ( e.length < length ) {

        e = '0' + e

    }

    return e

}

export const parseJSON = e => {

    if ( ! e ) {

        return null

    }

    try {

        return JSON.parse( e )

    } catch( f ) {

        return e

    }

}

export const reloadPage = () => setTimeout( () => window.location.reload(), 200 )