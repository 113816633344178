import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import DataTable from '../elements/DataTable'
import { Alert, Button, Card, Col, FloatingLabel, FormControl, Row } from 'react-bootstrap'
import { convertUrlParams, deepCopy, filterData } from '../../util'
import { SORT_ASC } from '../../util/constants'
import { createDate, fillDate, formatDateInput } from '../../util/date'

class Page extends React.Component {

    pageIcon
    pageTitle
    tableColumns
    tableDataUrl
    tableFilterKeys
    tableSortKeys

    constructor( props ) {

        super( props )

        this.state = {

            loadingError: null,
            searchedOnce: false,
            tableData:    null,
            tableRows:    null,

        }

    }

    componentDidMount() {

        this.loadTableData()

    }

    beforeLoadingTableData() {}

    changeUiValue( e ) {

        this.props.onUiValueChange( e.target.name, e.target.value )

    }

    changeTableFilter( e ) {

        this.props.onTableFilterChange( e.target.value )

        setTimeout( () => this.filterAndSortTableData(), 100 )

    }

    errorLoadingData( loadingError ) {

        this.setState( { loadingError } )

    }

    filterAndSortTableData() {

        if ( ! this.state.tableData ) {

            return

        }

        let tableRows = deepCopy( this.state.tableData )

        if ( this.props.tableFilter && this.tableFilterKeys ) {

            tableRows = filterData( tableRows, this.tableFilterKeys, this.props.tableFilter )

        }

        if ( this.props.tableSorting && this.tableSortKeys ) {

            tableRows.sort( ( a, b ) => {

                const aVal = a[ this.tableSortKeys[ this.props.tableSorting.column ] ]
                const bVal = b[ this.tableSortKeys[ this.props.tableSorting.column ] ]

                return this.props.tableSorting.direction === SORT_ASC ? ( aVal > bVal ? 1 : aVal < bVal ? -1 : 0 ) : ( aVal > bVal ? -1 : aVal < bVal ? 1 : 0 )

            } )

        }

        this.setState( { tableRows } )

    }

    getTableHeaders() {

        return null

    }

    handleTableSortingChange( e ) {

        this.props.onTableSortingChange( e )

        setTimeout( () => this.filterAndSortTableData(), 100 )

    }

    loadTableData() {

        if ( ! this.props.date && ! this.props.name && ! this.props.passNo ) {

            return

        }

        this.setState( { loadingError: null, searchedOnce: true, tableData: null, tableRows: null }, () => {

            const data = {

                token:       this.props.token,

            }

            if ( this.props.date ) {

                const date = createDate( this.props.date )

                data.paymentFrom = data.from = date.toJSON()
                data.paymentTo   = data.to   = fillDate( date ).toJSON()

            }

            if ( this.props.name ) {

                data.name = this.props.name

            }

            if ( this.props.passNo ) {

                data.passNr = this.props.passNo

            }

            this.beforeLoadingTableData()

            this.props.onToggleIsLoading( true )

            setTimeout( () => {

                ajax( getDataUrl( this.tableDataUrl ) + '?' + convertUrlParams( data ), null, { method: 'POST' } )
                    .then( e => e.StatusId > 0 && e.Data ? this.setState( { tableData: e.Data }, () => this.filterAndSortTableData() ) : this.errorLoadingData( e ) )
                    .catch( e => this.errorLoadingData( e ) )
                    .finally( () => this.props.onToggleIsLoading( false ) )

            }, 400 )

        } )

    }

    renderFooter() {

        return null

    }

    renderSubheaderBlock() {

        return null

    }

    renderTableFilter() {

        return (

            <FloatingLabel controlId='tableFilter' label={ _( 'Filter' ) } className='mb-3'>

                <FormControl value={ this.props.tableFilter } onChange={ e => this.changeTableFilter( e ) } className={ this.props.tableFilter ? 'border-warning' : '' } />

            </FloatingLabel>

        )

    }

    renderTableOptions() {

        return null

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon={ this.pageIcon } className='subheader-icon' /> { this.pageTitle }

                    </h1>

                    { this.renderSubheaderBlock() }

                </div>

                <Card bg='dark'>

                    <Card.Body>

                        <Row>

                            <Col xs='6' md='2'><FloatingLabel controlId='date' label={ _( 'Datum' ) } className='mb-1'><FormControl type='date' value={ this.props.date ? formatDateInput( this.props.date ) : '' } name='date' onChange={ e => this.changeUiValue( e ) } /></FloatingLabel></Col>

                            <Col xs='6' md='4'><FloatingLabel controlId='name' label={ _( 'Name' ) } className='mb-1'><FormControl value={ this.props.name } name='name' onChange={ e => this.changeUiValue( e ) } /></FloatingLabel></Col>

                            <Col xs='6' md='4'><FloatingLabel controlId='passNo' label={ _( 'Passnr.' ) } className='mb-1'><FormControl value={ this.props.passNo } name='passNo' onChange={ e => this.changeUiValue( e ) } /></FloatingLabel></Col>

                            <Col xs='6' md='2'>

                                <div className='d-grid'>

                                    <Button variant='info' className='mb-1' disabled={ this.props.isLoading || ( ! this.props.date && ! this.props.passNo && ! this.props.name ) } onClick={ () => this.loadTableData() }>

                                        <div><Icon icon='search' /></div>

                                        <div>{ _( 'Suchen' ) }</div>

                                    </Button>

                                </div>

                            </Col>

                        </Row>

                    </Card.Body>

                </Card>

                { this.renderTableOptions() }

                {

                    this.state.loadingError ?

                        <Alert variant='warning'>

                            <h1>{ _( 'Fehler' ) }</h1>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <p>{ JSON.stringify( this.state.loadingError ) }</p>

                            <p><Button onClick={ () => this.loadTableData() }>{ _( 'Erneut versuchen' ) }</Button></p>

                        </Alert>

                    : ! this.state.searchedOnce ?

                        <p className='m-5 text-center'>{ _( 'Bitte Suche ausführen.' ) }</p>

                    :

                        <DataTable
                            cols={ this.tableColumns }
                            filter={ this.props.tableFilter }
                            headers={ this.getTableHeaders() }
                            onSortingChange={ e => this.handleTableSortingChange( e ) }
                            rows={ this.state.tableRows }
                            sorting={ this.props.tableSorting }
                        />

                }

                { this.renderFooter() }

            </>

        )

    }

}

export default Page