import { translate as _ } from '@hockeydata/skynet'
import { Col, Row } from 'react-bootstrap'
import { getLicenseTypeLabel } from '../../util'
import { formatDate } from '../../util/date'
import Page from '../templates/Page'

class Licenses extends Page {

    constructor( props ) {

        super( props )

        this.pageIcon        = 'id-card'
        this.pageTitle       = _( 'Lizenzen' )
        this.tableColumns    = this.getTableColumns()
        this.tableDataUrl    = 'api/Admin/GetLicenses'
        this.tableFilterKeys = [ 'Name', 'PassNr', 'LicenseNr' ]
        this.tableHeaders    = this.getTableHeaders()
        this.tableSortKeys   = [ 'Name', 'PassNr', 'LicenseNr', 'Type', 'From', 'To' ]

    }

    getTableColumns() {

        return [

            { key: 'Name' },
            { key: 'PassNr' },
            { key: 'LicenseNr' },
            { fn: e => getLicenseTypeLabel( e.Type ) },
            { fn: e => formatDate( e.From ) },
            { fn: e => formatDate( e.To ) },

        ]

    }

    getTableHeaders() {

        return [ _( 'Name' ), _( 'Passnr.' ), _( 'Lizenznr.' ), _( 'Lizenz' ), _( 'Von' ), _( 'Bis' ) ]

    }

    renderTableOptions() {

        return (

            <Row className='mt-3'>

                <Col>

                    { this.renderTableFilter() }

                </Col>

            </Row>

        )

    }

}

export default Licenses