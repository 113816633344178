import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import storage from 'redux-persist/lib/storage'
import { combineReducers, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createRoot } from 'react-dom/client'
import { reducers } from '@hockeydata/skynet'
import App from './js/containers/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ui } from './js/store/reducers'

import './css/imports.scss'
import '@hockeydata/skynet/dist/index.css'
import './css/index.scss'

const combinedReducers = combineReducers( { app: reducers.app, auth: reducers.auth, ui } )
const root             = createRoot( document.getElementById( 'app-root' ) )
const persistConfig    = { key: 'single-license-admin-root', storage, blacklist: [] }
const persistedReducer = persistReducer( persistConfig, combinedReducers )
const store            = createStore( persistedReducer )
const persistor        = persistStore( store )

root.render(

    <Provider store={ store }>

      <PersistGate persistor={ persistor }>

          <App />

      </PersistGate>

  </Provider>

)

serviceWorkerRegistration.register( {

    onUpdate: registration => {

        if ( registration && registration.waiting ) {

            window.appUpdateAvailable = true

        }

    }

} )