import { lz } from '.'
import { weekdaysShort } from './enum'

export const createDate = e => {

    if ( e === null || e === undefined ) {

        return new Date()

    }

    return new Date( e )

}

export const fillDate = e => {

    e.setUTCMilliseconds( 999 )
    e.setUTCSeconds(       59 )
    e.setUTCMinutes(       59 )
    e.setUTCHours(         23 )

    return e

}

export const formatDate = ( e, options ) => {

    const defaultOptions = {

        month:   '', // String; 'none' = empty, everything else = 03
        weekday: '', // String; 'short' = Mo, everything else = empty
        year:    '', // String; 'long' = 2022, 'none' = empty, everything else = 22

    }

    e       = createDate( e )
    options = options ? { ...defaultOptions, ...options } : defaultOptions

    return (

        (

            options.weekday === 'short' ?

                weekdaysShort[ e.getDay() ] + ', '

            :

                ''

        )

        +

        lz( e.getDate() ) + '.' +

        (

            options.month === 'none' ?

                ''

            :

                lz( e.getMonth() + 1 ) + '.'

        )

        +

        (
            options.year === 'long' ?

                e.getFullYear()

            : options.year === 'none' ?

                ''

            :

                String( e.getFullYear() ).substring( 2 )

        )

    )

}

export const formatDateInput = e => {

    e = createDate( e )

    return lz( e.getFullYear(), 4 ) + '-' + lz( e.getMonth() + 1 ) + '-' + lz( e.getDate() )

}

export const formatTime = e => {

    e = createDate( e )

    return lz( e.getHours() ) + ':' + lz( e.getMinutes() )

}